<template>
  <div class="annonces" :class="{ column }">
    <div class="annonces-title__header">
      <div class="annonces-title">
        <slot name="title">
          <h2>
            Annonces rentables à :
            <span class="purple">{{ city }}</span>
          </h2>
        </slot>
      </div>
    </div>
    <client-only>
      <template v-if="column">
        <template v-for="item in columnAnnoncesToDisplay" :key="item.id">
          <AnnonceCard class="ad-card-col" :ad="item" :skeleton="displaySkeleton" />
        </template>
      </template>
      <article-swipe-container v-else :items="annoncesToDisplay">
        <template #item="{ item }">
          <AnnonceCard :key="item.id" :ad="item" :skeleton="displaySkeleton" />
        </template>
      </article-swipe-container>
    </client-only>

    <slot name="display-all">
      <NuxtLink class="link" :to="annoncesLink">Voir toutes les annonces rentables à {{ city }} »</NuxtLink>
    </slot>
  </div>
</template>

<script setup>
import { locationToQuery } from '../composables/tub-api'

const props = defineProps({
  city: {
    type: String,
    required: false,
  },
  postalCode: {
    type: [Number, String],
    required: false,
  },
  column: {
    type: Boolean,
    default: false,
  },
  hideAnnouncesWithId: {
    type: Array,
    default: () => [],
  },
  limit: {
    type: Number,
    default: 6,
  },
  random: {
    type: Boolean,
    default: false,
  },
  exclusive: {
    type: Boolean,
    default: false,
  },
})

const displaySkeleton = ref(true)
const annonces = ref([])

// --------------------------------- COMPUTED ---------------------------------
const annoncesLink = computed(() => ({
  name: 'biens-rentables-custom-search',
  query: {
    locations: locationToQuery({
      cp: `${props.postalCode}`,
      ville: `${props.city}`,
      distance: 'all-city',
    }),
    rendementMax: 17,
    sorting: '-rendement',
  },
}))

const annoncesToDisplay = computed(() => {
  if (displaySkeleton.value) {
    return Array.from({ length: props.limit }, (i) => ({ id: `skeleton-${i}` }))
  }

  const ads = annonces.value.filter((ad) => !props.hideAnnouncesWithId.includes(ad.id))

  return ads.slice(0, props.limit)
})

const columnAnnoncesToDisplay = computed(() => annoncesToDisplay.value.slice(0, 3))

onMounted(async () => {
  const params = {
    sort: '-hzExclusive,-rendement',
    limit: props.limit + props.hideAnnouncesWithId.length,
    filter: {},
  }

  if (props.city && props.postalCode) {
    params.filter.locations = [
      {
        cp: props.postalCode,
        ville: props.city,
        distance: 20,
      },
    ]
  }

  if (props.exclusive) {
    params.filter.hzExclusive = true
  }

  if (props.random) {
    params.filter.random = true
  }

  const ads = await useAdsApi().getAnnonces({
    ...params,
    filter: JSON.stringify(params.filter),
  })
  annonces.value = ads.data
  displaySkeleton.value = false
})
</script>

<style lang="less" scoped>
:deep(.swipe-container) {
  width: calc(224px * 2 + 22px);

  .scroll-container {
    margin-left: -22px;
    margin-right: -22px;
  }

  .scroll-arrow {
    &.right {
      right: -12px;
    }
    &.left {
      left: -12px;
    }
  }
}

:deep(.real-estate-card.ad-card-col) {
  margin-bottom: 20px;
  width: 100%;
}

:deep(.real-estate-card:not(.ad-card-col)) {
  margin-left: 22px;

  &:last-child {
    margin-right: 22px;
  }
}

.annonces {
  .annonces-title__header {
    display: flex;
    flex-direction: column;

    .annonces-title {
      font-size: 22px;
      font-weight: 400;
      margin: 0;

      & > :deep(h2) {
        font-size: 26px;
        font-weight: var(--ds-weight-semi-bold);
        margin-bottom: 20px;

        .purple {
          color: var(--ds-color-primary-100);
        }
      }
    }
  }

  :deep(.link) {
    color: var(--ds-color-primary-100);
    font-size: 16px;
    font-weight: var(--ds-weight-semi-bold);
    position: relative;
    text-decoration: none;
    font-style: normal;

    .icon {
      position: absolute;
      top: 35%;
      width: 16px;
      height: 16px;
      margin-left: 8px;
      margin-top: -2px;
    }

    &-column {
      font-size: 18px;
      font-weight: var(--ds-weight-semi-bold);
    }
  }

  @media @bp-desktop {
    .annonces-title__header {
      flex-direction: row;
      align-items: baseline;
      column-gap: 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 95%;

      .annonces-title {
        font-size: 32px;
      }
    }
  }
}
</style>
